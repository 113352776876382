import React, { useEffect } from 'react';

import { Drawer, Form, Input, Button } from 'antd';

import REQUESTS from '../../../api/requests';

export default function NotesDrawer({ open, onClose, current, getDevices }) {
    const [form] = Form.useForm();

    const onFinish = (values) => {

        if (!current?.id) return;

        const body = {
            id: current.id,
            notes: values.notes
        }

        REQUESTS.DEVICE.NOTES_UPDATE(body, () => {
            onClose();
            getDevices();
        }, (err) => { })
    }

    useEffect(() => {
        if (open) {
            form.setFieldsValue({
                notes: current.notes
            })
        } else {
            form.resetFields()
        }
    }, [open])

    return (
        <Drawer
            title="Edit Notes"
            open={open}
            onClose={onClose}
        >
            <Form
                form={form}
                onFinish={onFinish}
                layout="vertical"
            >
                <Form.Item
                    label="Notes"
                    name="notes"
                    rules={[
                        { required: true, message: 'Please input your notes!' },
                        { max: 255, message: 'Max length is 255 characters' }
                    ]}
                >
                    <Input.TextArea maxLength={255} showCount />
                </Form.Item>
                <Form.Item className='center-btn'>
                    <Button type="primary" htmlType="submit">Submit</Button>
                </Form.Item>
            </Form>
        </Drawer>
    )
}
