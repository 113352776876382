import CONSTANTS from "../config";

const URLS = {
    login: `${CONSTANTS.API_HOST}/admin/login`,
    admin: `${CONSTANTS.API_HOST}/admin/profile`,
    get_totals: `${CONSTANTS.API_HOST}/admin/total_info`,
    online_clients: `${CONSTANTS.API_HOST}/admin/online_clients`,
    admin_server: `${CONSTANTS.API_HOST}/admin/server`,
    used_devices: `${CONSTANTS.API_HOST}/admin/most_used_devices`,
    chart: `${CONSTANTS.API_HOST}/admin/chart`,
    admin_devices: `${CONSTANTS.API_HOST}/admin/devices`,
    admin_playlists: `${CONSTANTS.API_HOST}/admin/playlist`,
    admin_languages: `${CONSTANTS.API_HOST}/admin/languages`,
    admin_translations: `${CONSTANTS.API_HOST}/admin/translations`,
    admin_reseller: `${CONSTANTS.API_HOST}/admin/reseller`,
    DISABLE: `${CONSTANTS.API_HOST}/admin/disable`,
    activation_package: `${CONSTANTS.API_HOST}/admin/activation_package`,
    referral_status: `${CONSTANTS.API_HOST}/admin/referral_status`,
    black_lists: `${CONSTANTS.API_HOST}/admin/black_lists`,
    activate_device: `${CONSTANTS.API_HOST}/admin/activate_device`,
    reset_free_trial: `${CONSTANTS.API_HOST}/admin/reset_free_trial`,
    withdraw: `${CONSTANTS.API_HOST}/admin/withdraw`,
    app_info: `${CONSTANTS.API_HOST}/admin/app_info`,
    server_default: `${CONSTANTS.API_HOST}/admin/mark_server_as_default`,
    adminEarnings: `${CONSTANTS.API_HOST}/admin/earnings`,
    resellerActivation: `${CONSTANTS.API_HOST}/admin/reseller_activation`,
    payments: `${CONSTANTS.API_HOST}/admin/payments`,
    referral_links: `${CONSTANTS.API_HOST}/admin/referral_links`,
    words: `${CONSTANTS.API_HOST}/admin/words`,
    currencies: `${CONSTANTS.API_HOST}/admin/currencies`,
    user_packages: `${CONSTANTS.API_HOST}/admin/packages`,
    smptConfig: `${CONSTANTS.API_HOST}/admin/smtp`,
    admin_playlist: `${CONSTANTS.API_HOST}/admin/playlist`,
    device_chart: `${CONSTANTS.API_HOST}/admin/device_chart`,
    chart_payments: `${CONSTANTS.API_HOST}/admin/statistics/payments`,
    get_packages: `${CONSTANTS.API_HOST}/admin/api/packages`,  ///
    server_state: `${CONSTANTS.API_HOST}/admin/server_state`,
    forgot_password: `${CONSTANTS.API_HOST}/admin/forgot_password`,
    reset_password: `${CONSTANTS.API_HOST}/admin/reset_password`,
    news: `${CONSTANTS.API_HOST}/admin/news`, //
    tickets: `${CONSTANTS.API_HOST}/admin/tickets`, ///
    tickets_viewed: `${CONSTANTS.API_HOST}/admin/tickets/viewed`, //
    tickets_answer: `${CONSTANTS.API_HOST}/admin/tickets/answer`, ///
    payment_settings: `${CONSTANTS.API_HOST}/admin/payment_settings`,
    edit_payment_settings: `${CONSTANTS.API_HOST}/admin/payment/settings`,  //
    chat: `${CONSTANTS.API_HOST}/admin/chat`,
    chat_messages: `${CONSTANTS.API_HOST}/admin/chat/messages`,
    view_message: `${CONSTANTS.API_HOST}/admin/chat/messages/view`,
    coupons: `${CONSTANTS.API_HOST}/admin/coupons`, //
    api_app_info: `${CONSTANTS.API_HOST}/admin/api/app_info`, ///
    change_recommending: `${CONSTANTS.API_HOST}/admin/change_recommending`,
    widgets: `${CONSTANTS.API_HOST}/admin/widgets`, //
    chart_devices: `${CONSTANTS.API_HOST}/admin/chart/devices`,
    activations: `${CONSTANTS.API_HOST}/admin/reseller_activation`,

    IMA_CONFIG: `${CONSTANTS.API_HOST}/admin/ima/admin`, //

    COUNTRIES: `${CONSTANTS.API_HOST}/admin/countries`,
    TRANSLATIONS: {
        GET: `${CONSTANTS.API_HOST}/admin/editor/get_web_settings`,
        EDIT: `${CONSTANTS.API_HOST}/admin/editor/update_web_settings`,
    },
    RESELLER_TRANSLATION_UPDATE: `${CONSTANTS.API_HOST}/admin/editor/reseller_translations`, ///

    REFERRAL: `${CONSTANTS.API_HOST}/admin/referrals`,
    RESELLER: `${CONSTANTS.API_HOST}/admin/reseller`,
    CAPTCHA: `${CONSTANTS.API_HOST}/admin/captcha`, //
    CAPTCHA_FOR_LOGIN: `${CONSTANTS.API_HOST}/admin/api/captcha`, //
    NOTIFICATION: `${CONSTANTS.API_HOST}/admin/notifications`, //
    NOTIFICATION_FREEZE: `${CONSTANTS.API_HOST}/admin/notifications/freeze`, //
    XTREAM_UI: `${CONSTANTS.API_HOST}/admin/xuione_config`, //
    RESELLER_ACTIVATIONS_HISTORY: `${CONSTANTS.API_HOST}/admin/res_activation_history`,
    RESELLER_SUBRESELLERS: `${CONSTANTS.API_HOST}/admin/subresellers`,
    TOTAL_DEVICES: `${CONSTANTS.API_HOST}/admin/total_devices`,
    RESELLERS_REGISTERED: `${CONSTANTS.API_HOST}/admin/total_resellers`,
    RESELLERS_ACTIVATION: `${CONSTANTS.API_HOST}/admin/reseller_activations`,
    DEVICE_PAYMENT: `${CONSTANTS.API_HOST}/admin/device_payments`,
    CATEGORIES: `${CONSTANTS.API_HOST}/admin/xtream_config`,
    CONTENT_CATEGORIES: `${CONSTANTS.API_HOST}/admin/xtream_categories`,
    GET_API_VERSION: `${CONSTANTS.API_HOST}/admin/api/version`, //
    DEVICE_DEACTIVEATE: `${CONSTANTS.API_HOST}/admin/deactivate_device`,
    playlist_logs: `${CONSTANTS.API_HOST}/admin/playlist_logs`,
    APPLICATIONS: `${CONSTANTS.API_HOST}/admin/application`,
    BACKUP_PLAYLIST: `${CONSTANTS.API_HOST}/admin/backup_playlist`,
    FREE_DNS: `${CONSTANTS.API_HOST}/admin/free_dns`, //
    SUBTITLE: `${CONSTANTS.API_HOST}/admin/subtitle`,
    DEVICE_LOGS: `${CONSTANTS.API_HOST}/admin/restore_logs`,
    TRANSFER_RESSELLER: `${CONSTANTS.API_HOST}/admin/transfer`,
    DEVICE_ACTIVATIONS: `${CONSTANTS.API_HOST}/admin/device_activations`,
    FREE_DNS_STATISTICS: `${CONSTANTS.API_HOST}/admin/free_dns/statistics`,
    PARENT_RESELLER: `${CONSTANTS.API_HOST}/admin/parent_reseller`,
    MIGRATE_DNS: `${CONSTANTS.API_HOST}/admin/migrate`,
    transfer_device: `${CONSTANTS.API_HOST}/admin/device/transfer`,
    validate_mac: `${CONSTANTS.API_HOST}/api/validate_mac?mac=`,
    devices_notes: `${CONSTANTS.API_HOST}/admin/device/notes`,
};

export default URLS;
