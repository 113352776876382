import React, { useEffect, useState } from 'react';

import { Table } from 'antd';

import REQUESTS from '../../../api/requests';

import getMyDate from '../../../components/getMyDate';

export default function TransferHistory({ mac }) {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
    });

    const columns = [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
            render: (text, record, index) => {
                const { current, pageSize } = pagination;
                return ((current - 1) * pageSize) + index;
            }
        },
        {
            title: 'To Mac',
            dataIndex: 'to_mac',
            key: 'to_mac',
        },
        {
            title: 'From MAC',
            dataIndex: 'from_mac',
            key: 'from_mac',
        },
        {
            title: 'Date',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (text) => text ? getMyDate(text) : 'N/A',
        }
    ]

    const handleTableChange = (pagination, filters, sorter) => {
        setPagination(pagination);
    };


    const getData = () => {
        setLoading(true);

        const query = {
            mac,
        };

        function callback(data) {
            setPagination({ ...pagination, total: data.count });
            setLoading(false);
            setData(data.rows);
        };

        function errorCallback(err) {
            setLoading(false);
        };

        REQUESTS.TRANSFER_DEVICE_HISTORY(query, callback, errorCallback)
    };

    useEffect(() => {
        let timeout = setTimeout(getData, 500);

        return () => clearTimeout(timeout);

    }, [pagination.current, pagination.pageSize]);

    return (
        <div>
            <p className="table-title">Transfer History</p>
            <Table
                onChange={handleTableChange}
                dataSource={data}
                columns={columns}
                loading={loading}
                pagination={{
                    position: ["bottomCenter"],
                    showSizeChanger: true,
                    ...pagination,
                }}
                scroll={{ x: "max-content" }}
                size="small"
            />
        </div>
    )
}
